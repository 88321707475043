import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Layout from "../../../components/Layout";
import SEO from "../../../components/SEO";
import Card from "../../../components/Cards";
import requests from "../../../services/requests";
import RegistrationData from "../components/RegistrationData";
import RegistrationImageExam from "../components/RegistrationImageExam";
import SubmenuDetail from "../components/SubmenuDetail";

const DriverDetail = props => {
    const [load, setLoad] = useState(false);
    const [entity, setEntity] = useState({});
    const [tabModal, setTabModal] = useState("Exame toxicológico");
    const [openModal, setOpenModal] = useState(false);

    const actionModal = () => {
        setTabModal("");
        setOpenModal(!openModal);
    };

    const closeModal = () => {
        setTabModal("Exame toxicológico");
        setOpenModal(false);
    };

    const getEntity = () => {
        setLoad(true);
        requests.showDriver(props["*"]).then(data => {
            setEntity(data);
            setLoad(false);
        });
    };

    useEffect(() => {
        getEntity();
    }, []);

    return (
        <Layout>
            <RegistrationData
                actionModal={closeModal}
                tabModal={tabModal}
                setTabModal={setTabModal}
                openModal={openModal}
                entity={entity}
                handleSuccess={() => {
                    closeModal();
                    getEntity();
                }}
            />
            {load ? (
                <div className="flex flex-col items-center">
                    <ClipLoader size={20} loading={load} />
                </div>
            ) : (
                <>
                    <SEO title={`Motorista ${entity?.user?.name}`} />
                    <SubmenuDetail driver={entity} name={entity?.user?.name} prevPath={props.location?.state?.prevPath} />
                    <div className="p-4 flex flex-wrap">
                        <div className=" w-1/2 lg:w-2/5 lg:pr-2 xs:pr-0 lg:mb-0 xs:mb-4">
                            <Card className="h-full">
                                <div className="flex items-center mb-5">
                                    <h5 className={`mr-6 text-roxo_oficial font-bold`}>Dados de cadastro</h5>
                                </div>
                                <div className="full">
                                    <div className="h-16 stripped">
                                        <label htmlFor='Data de nascimento' className=" mt-2 text-roxo_oficial font-bold">
                                            Data de nascimento
                                        </label>
                                        <p className="text-C6 text-sm">
                                            {entity?.informations?.registrationData?.birthDate}
                                        </p>
                                    </div>
                                </div>
                                <div className="full">
                                    <div className="h-16">
                                        <label htmlFor='CPF' className=" mt-2 text-roxo_oficial font-bold">
                                            CPF
                                        </label>
                                        <p className="text-C6 text-sm">
                                            {entity?.informations?.registrationData?.documentNumber}
                                        </p>
                                    </div>
                                </div>
                                <div className="full stripped">
                                    <div className="h-16">
                                        <label htmlFor='identityDocumentNumber' className=" mt-2 text-roxo_oficial font-bold">
                                            RG
                                        </label>
                                        <p className="text-C6 text-sm">
                                            {entity?.informations?.registrationData?.identityDocumentNumber}
                                        </p>
                                    </div>
                                </div>
                                <div className="full">
                                    <div className="h-16">
                                        <label htmlFor='CEP' className=" mt-2 text-roxo_oficial font-bold">
                                            CEP
                                        </label>
                                        <p className="text-C6 text-sm">
                                            {entity?.informations?.registrationData?.streetCode}
                                        </p>
                                    </div>
                                </div>
                                <div className="full stripped">
                                    <div className="h-16">
                                        <label htmlFor='streetAddress' className=" mt-2 text-roxo_oficial font-bold">
                                            Endereço
                                        </label>
                                        <p className="text-C6 text-sm">
                                            {entity?.informations?.registrationData?.streetAddress}
                                        </p>
                                    </div>
                                </div>
                                <div className="full">
                                    <div className="h-16">
                                        <label htmlFor='streetNumber' className=" mt-2 text-roxo_oficial font-bold">
                                            Número
                                        </label>
                                        <p className="text-C6 text-sm">
                                            {entity?.informations?.registrationData?.streetNumber}
                                        </p>
                                    </div>
                                </div>
                                <div className="full stripped">
                                    <div className="h-16">
                                        <label htmlFor='streetDistrict' className=" mt-2 text-roxo_oficial font-bold">
                                            Bairro
                                        </label>
                                        <p className="text-C6 text-sm">
                                            {entity?.informations?.registrationData?.streetDistrict}
                                        </p>
                                    </div>
                                </div>
                                <div className="full">
                                    <div className="h-16">
                                        <label htmlFor='state' className=" mt-2 text-roxo_oficial font-bold">
                                            Cidade
                                        </label>
                                        <p className="text-C6 text-sm">
                                            {entity?.informations?.registrationData?.state}
                                        </p>
                                    </div>
                                </div>
                                <div className="full stripped">
                                    <div className="h-16">
                                        <label htmlFor='city' className=" mt-2 text-roxo_oficial font-bold">
                                            Estado
                                        </label>
                                        <p className="text-C6 text-sm">
                                            {entity?.informations?.registrationData?.city}
                                        </p>
                                    </div>
                                </div>
                                <div className="inline-block w-full mt-5">
                                    <button
                                        onClick={() => actionModal()}
                                        className="mt-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right"
                                    >
                                        <span>Atualizar dados</span>
                                    </button>
                                </div>
                            </Card>
                        </div>
                        <div className=" w-1/2 lg:w-3/5 lg:pr-2 xs:pr-0 lg:mb-0 xs:mb-4">
                            <Card className="h-full">
                                <div className="w-full h-full">
                                    <div className="flex items-center mb-5">
                                        <h5 className={`mr-6 text-roxo_oficial font-bold`}>Informações adicionais</h5>
                                    </div>
                                    <button
                                        onClick={() => setTabModal("Exame toxicológico")}
                                        className={`mr-4 cursor-pointer ${
                                            tabModal === "Exame toxicológico" ? "text-roxo_oficial" : "text-C6"
                                        }`}
                                    >
                                        Exame toxicológico
                                    </button>
                                    <button
                                        onClick={() => setTabModal("ANTT")}
                                        className={`mr-4 cursor-pointer ${tabModal === "ANTT" ? "text-roxo_oficial" : "text-C6"}`}
                                    >
                                        ANTT
                                    </button>
                                    <button
                                        onClick={() => setTabModal("Comprovante de residência")}
                                        className={`mr-4 cursor-pointer ${
                                            tabModal === "Comprovante de residência" ? "text-roxo_oficial" : "text-C6"
                                        }`}
                                    >
                                        Comprovante de residência
                                    </button>
                                    <button
                                        onClick={() => setTabModal("Pagamento")}
                                        className={`mr-4 cursor-pointer ${tabModal === "Pagamento" ? "text-roxo_oficial" : "text-C6"}`}
                                    >
                                        Pagamento
                                    </button>
                                    <button
                                        onClick={() => setTabModal("CNH")}
                                        className={`mr-4 cursor-pointer ${tabModal === "CNH" ? "text-roxo_oficial" : "text-C6"}`}
                                    >
                                        CNH
                                    </button>
                                    <div className="mt-10">
                                        {tabModal === "Exame toxicológico" && <RegistrationImageExam entity={entity} getEntity={getEntity}/>}
                                        {tabModal === "CNH" && <RegistrationImageExam />}
                                        {tabModal === "ANTT" && <RegistrationImageExam />}
                                        {tabModal === "Comprovante de residência" && <RegistrationImageExam />}
                                        {tabModal === "Pagamento" && <RegistrationImageExam />}
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className="w-full p-4">
                        <Card className="h-full">
                            <div className="flex items-center mb-5">
                                <h5 className={`mr-6 text-roxo_oficial font-bold`}>Informações de viagens</h5>
                            </div>
                        </Card>
                    </div>
                </>
            )}
        </Layout>
    );
};

export default DriverDetail;
