import React from "react";
import { Formik, ErrorMessage } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import Modal from "../../../components/Modals";
import Input from "../../../components/Input/Input";
import requests from "../../../services/requests";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
import * as yup from "yup";
import InputMasked from "../../../components/Input/InputMasked";

registerLocale("pt", pt);

const RegistrationData = ({ actionModal, openModal, handleSuccess, entity }) => {
    return (
        <Modal
            onClose={actionModal}
            size="xs:w-11/12 lg:w-6/12 min-h-40%"
            show={openModal}
            title={
                <div className="flex items-center">
                    <h5 className={`mr-2 text-roxo_oficial font-bold`}>
                        Cadastro de informações do motorista
                    </h5>
                </div>
            }
        >
            <Formik
                enableReinitialize={true}
                initialValues={{
                    documentNumber: entity ? entity?.informations?.registrationData?.documentNumber : "",
                    identityDocumentNumber: entity ? entity?.informations?.registrationData?.identityDocumentNumber : "",
                    birthDate: entity ? entity?.informations?.registrationData?.birthDate : "",
                    streetAddress: entity ? entity?.informations?.registrationData?.streetAddress : "",
                    streetNumber: entity ? entity?.informations?.registrationData?.streetNumber : "",
                    streetCode: entity ? entity?.informations?.registrationData?.streetCode : "",
                    state: entity ? entity?.informations?.registrationData?.state : "",
                    city: entity ? entity?.informations?.registrationData?.city : "",
                    streetDistrict: entity ? entity?.informations?.registrationData?.streetDistrict : "",
                }}
                validationSchema={yup.object().shape({
                    documentNumber: yup.string().required("Campo obrigatório.").nullable(),
                    identityDocumentNumber: yup.string().required("Campo obrigatório.").nullable(),
                    birthDate: yup.string().required("Campo obrigatório.").nullable(),
                    streetAddress: yup.string().required("Campo obrigatório.").nullable(),
                    streetNumber: yup.string().required("Campo obrigatório.").nullable(),
                    streetCode: yup.string().required("Campo obrigatório.").nullable(),
                    state: yup.string().required("Campo obrigatório.").nullable(),
                    city: yup.string().required("Campo obrigatório.").nullable(),
                    streetDistrict: yup.string().required("Campo obrigatório.").nullable(),
                })}
                onSubmit={(values, { setFieldError, setSubmitting }) => {
                    setSubmitting(true);
                    let aux = {
                        'information': JSON.stringify(values),
                        'documentNumber': values.documentNumber,
                        'driver': entity.id,
                        'modality': 'REGISTRATION_DATA',
                        'status': 'ENABLED',
                    }

                    if (entity?.informations?.registrationData?.identifier) {
                        requests
                            .editDriverInformation(entity.informations.registrationData.identifier, aux)
                            .then(response => {
                                setSubmitting(false);
                                handleSuccess();
                                toast.success("Informações editadas com sucesso!");
                            })
                            .catch(error => {
                                setSubmitting(false);
                            });
                    } else {
                        requests
                            .addDriverInformation(aux)
                            .then(response => {
                                setSubmitting(false);
                                handleSuccess();
                                toast.success("Informações cadastrada com sucesso!");
                            })
                            .catch(error => {
                                setSubmitting(false);
                            });
                    }
                }}
            >
                {({ setFieldValue, handleChange, handleBlur, handleSubmit, isSubmitting, values }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="w-full">
                            <div className="flex">
                                <div className="w-6/12">
                                    <label htmlFor="birthDate" className="text-roxo_oficial font-bold">
                                        Data de nascimento
                                    </label>
                                    <InputMasked
                                        placeholder={"Selecione a data de nascimento"}
                                        name="birthDate"
                                        id="birthDate"
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.birthDate}
                                        mask="99/99/9999"
                                    />
                                    <ErrorMessage
                                        component="p"
                                        className="mt-1 text-red mb-4 font-light text-xs"
                                        name="birthDate"
                                    />
                                </div>
                                <div className="w-6/12 ml-4">
                                    <label htmlFor="documentNumber" className="text-roxo_oficial font-bold">
                                        CPF
                                    </label>
                                    <InputMasked
                                        placeholder={"Digite seu CPF"}
                                        name="documentNumber"
                                        id="documentNumber"
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.documentNumber}
                                        mask="999.999.999-99"
                                    />
                                    <ErrorMessage
                                        component="label"
                                        name="documentNumber"
                                        className="text-red font-light w-full"
                                    />
                                </div>
                            </div>
                            <div className="flex mt-4">
                                <div className="w-6/12">
                                    <label htmlFor="identityDocumentNumber" className="text-roxo_oficial font-bold">
                                        RG
                                    </label>
                                    <InputMasked
                                        placeholder="Digite o número do RG"
                                        name="identityDocumentNumber"
                                        id="identityDocumentNumber"
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.identityDocumentNumber}
                                        mask="99.999.999-99"
                                    />
                                    <ErrorMessage
                                        component="label"
                                        name="identityDocumentNumber"
                                        className="text-red font-light w-full"
                                    />
                                </div>
                                <div className="w-6/12 ml-4">
                                    <label htmlFor="streetCode" className="text-roxo_oficial font-bold">
                                        CEP
                                    </label>
                                    <InputMasked
                                        placeholder="Digite o número do CEP"
                                        name="streetCode"
                                        id="streetCode"
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.streetCode}
                                        mask="99999-999"
                                    />
                                    <ErrorMessage
                                        component="p"
                                        className="mt-1 text-red mb-4 font-light text-xs"
                                        name="streetCode"
                                    />
                                </div>
                            </div>
                            <div className="flex mt-3">
                                <div className="w-6/12">
                                    <label htmlFor="streetAddress" className="text-roxo_oficial font-bold">
                                        Endereço
                                    </label>
                                    <Input
                                        name="streetAddress"
                                        id="streetAddress"
                                        type="text"
                                        placeholder="Digite o bairro"
                                        value={values.streetAddress}
                                    />
                                    <ErrorMessage
                                        component="p"
                                        className="mt-1 text-red mb-4 font-light text-xs"
                                        name="streetAddress"
                                    />
                                </div>
                                <div className="w-6/12 ml-4">
                                    <label htmlFor="streetNumber" className="text-roxo_oficial font-bold">
                                        Número
                                    </label>
                                    <Input
                                        name="streetNumber"
                                        id="streetNumber"
                                        type="text"
                                        placeholder="Digite o número"
                                        value={values.streetNumber}
                                    />
                                    <ErrorMessage
                                        component="p"
                                        className="mt-1 text-red mb-4 font-light text-xs"
                                        name="streetDistrict"
                                    />
                                </div>
                            </div>
                            <div className="flex mt-3">
                                <div className="w-6/12">
                                    <label htmlFor="streetDistrict" className="text-roxo_oficial font-bold">
                                        Bairro
                                    </label>
                                    <Input
                                        name="streetDistrict"
                                        id="streetDistrict"
                                        type="text"
                                        placeholder="Digite o bairro"
                                        value={values.streetDistrict}
                                    />
                                    <ErrorMessage
                                        component="p"
                                        className="mt-1 text-red mb-4 font-light text-xs"
                                        name="streetDistrict"
                                    />
                                </div>
                                <div className="w-6/12 ml-4">
                                    <label htmlFor="state" className="text-roxo_oficial font-bold">
                                        Estado
                                    </label>
                                    <Input
                                        name="state"
                                        id="state"
                                        type="text"
                                        placeholder="Selecione o estado"
                                        value={values.state}
                                    />
                                    <ErrorMessage component="p" className="mt-1 text-red mb-4 font-light text-xs" name="state" />
                                </div>
                            </div>
                            <div className="flex mt-3">
                                <div className="w-6/12">
                                    <label htmlFor="city" className="text-roxo_oficial font-bold">
                                        Cidade
                                    </label>
                                    <Input
                                        name="city"
                                        id="city"
                                        type="text"
                                        placeholder="Selecione a cidade"
                                        value={values.city}
                                    />
                                    <ErrorMessage component="p" className="mt-1 text-red mb-4 font-light text-xs" name="city" />
                                </div>
                            </div>
                        </div>
                        <button
                            className="bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mt-10 mb-10"
                            type="submit"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? (
                                <>
                                    Salvando <ClipLoader size={10} loading={isSubmitting} />
                                </>
                            ) : (
                                <>Salvar</>
                            )}
                        </button>
                    </form>
                )}
            </Formik>
        </Modal>
    );
};

export default RegistrationData;
