import React from "react";
import { IoIosArrowBack } from "react-icons/io";

import { Link } from "gatsby";
import Card from "../../../components/Cards";

const SubmenuDetail = ({ name, prevPath }) => {
    return (
        <Card className="justify-between flex flex-wrap lg:px-4 lg:py-3">
            <div className="xs:w-full lg:w-2/5 flex items-center">
                <Link to={prevPath || `/gestao-de-motoristas/kanban`}>
                    <IoIosArrowBack className="text-roxo_oficial sm:h-14 sm:w-14  md:h-6 md:w-6 xs:h-10 xs:w-10" />
                </Link>
                <h2 className="ml-3">{`Motorista ${name}`}</h2>
            </div>
            <div className="flex  lg:w-3/5  items-center justify-items-center self-center border-l border-c6 pl-4 flex-wrap">
                <div className="mr-4"></div>{" "}
            </div>
        </Card>
    );
};
export default SubmenuDetail;
