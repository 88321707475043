import React, { useState } from "react";
import { Formik } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import requests from "../../../services/requests";
import InputStyled from "../../../components/styles/InputStyled";
import { FaTrashAlt } from "react-icons/fa";

const RegistrationImageExam = ({ entity, getEntity }) => {
    const [tempFiles, setTempFiles] = useState([]);

    const removeTempFile = ({ values, index, setValues, setErrors }) => {
        if (typeof values.files[index]?.identifier === "undefined") {
            const form = document.getElementById("form");
            form.reset();
            setErrors({});

            delete values.files[index];

            setValues({ ...values });
        } else {
            requests
                .deleteFileDriverInformation(entity.informations.toxicoLogicalExam.identifier, entity.informations.toxicoLogicalExam.files[index].identifier)
                .then(response => {
                    getEntity();
                    // delete values.files[index];
                    // setValues({ ...values });

                    toast.success("Exame toxicológico removido com sucesso!");
                })
                .catch(error => {
                });
        }
    };

    return (
        <>
            <Formik
                initialValues={{
                    files: entity?.informations?.toxicoLogicalExam?.files || [],
                }}
                onSubmit={(values, { setFieldError, setSubmitting }) => {
                    setSubmitting(true);

                    tempFiles.length && tempFiles.map((temp) => {
                        const data = new FormData();
                        data.append(`file_upload_multiple[files]`, temp);
                        data.append(`modality`, 'TOXICOLOGICAL_EXAM');
                        data.append(`driver`, entity.id);
                        data.append(`status`, 'ENABLED');
                        return (

                            requests
                                .uploadFileDriverInformation(entity.informations.toxicoLogicalExam.identifier, data)
                                .then(response => {
                                    toast.success("Exame toxicológico atualizado com sucesso!");
                                    getEntity();
                                    setSubmitting(false);
                                })
                                .catch(error => {
                                    setSubmitting(false);
                                })
                        )

                    })
                }}
            >
                {({ setValues, handleSubmit, isSubmitting, values, setFieldValue, setErrors }) => (
                    <form id="form" onSubmit={handleSubmit}>
                        <InputStyled
                            hidden={true}
                            name="files"
                            id="files"
                            accept=".pdf, .docx, .xml, .jpeg, .png"
                            multiple={true}
                            onChange={e => {
                                let aux = Array.from(e.target?.files);
                                let auxFiles = [];
                                aux.forEach((file, index) => {
                                    if (aux.length > 2) {
                                        toast.error("Deve selecionar ou arrastart apenas duas imagens!");
                                        return;
                                    }
                                    // let key = file.name.replaceAll(".", "") + file.lastModified;
                                    setFieldValue(`files[${index}]`, file);
                                    auxFiles.push(file);
                                });
                                setTempFiles(auxFiles);
                            }}
                        />
                        <div className="flex mt-10">
                            {values.files.length > 0 && values.files.map((file, index) => {
                                return (
                                    <>
                                        {typeof file !== "undefined" && (
                                            <div className="w-full ml-2" key={index}>
                                                <div className="flex float-right">
                                                    <button
                                                        className="p-2 rounded-md bg-canal_vermelho mb-2"
                                                        type="button"
                                                        onClick={() => removeTempFile({ values, index, setValues, setErrors })}
                                                    >
                                                        <FaTrashAlt color="#fff" />
                                                    </button>
                                                </div>
                                                <img
                                                    className="border border-roxo_oficial flex flex-col justify-end items-center"
                                                    src={typeof file?.identifier === "string" ? process.env.REACT_APP_BASE_URL + `/open/information/file/${file?.identifier}/view` : URL.createObjectURL(file)}
                                                alt=""
                                                />
                                            </div>
                                        )}
                                    </>
                                )
                            })}
                        </div>
                        <button
                            className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mt-10"
                            type="submit"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? (
                                <>
                                    Salvando <ClipLoader size={10} loading={isSubmitting} />
                                </>
                            ) : (
                                <>Salvar</>
                            )}
                        </button>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default RegistrationImageExam;
